<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                Intranet logs
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="data"
              :sort-mapping="sortMapping"
              :title="'logs'"
              :total="dataTotal"
              :fetch-path="'intranetLogs/filter'"
              :filter-path="'intranetLogs/filter'"
            >
              <template v-slot="{field, showInfoSheet}">
                <td class="text-end">
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete area & group"
                    @click="showInfoSheet(field)"
                  >
                    <a
                      class="btn btn-icon rounded-circle"
                    >
                      <i data-feather="eye" />
                    </a>
                  </span>
                </td>
              </template>
              <template #info-sheet-item="{item}">
                <div class="offcanvas-body offcanvas-body--view">
                  <div class="mb-1 offcanvas-body--view--title">
                    <p>User<br>
                      <strong>{{ item.causer.name }}</strong></p>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <p>Type of content<br>
                      <strong>{{ item.class_name }}</strong></p>
                  </div>
                  <div class="mb-1">
                    <p>Type of action<br>
                      <strong>{{ item.action_type }}</strong></p>
                  </div>
                  <div class="mb-1">
                    <p>Date<br>
                      <strong>{{ item.date }}</strong></p>
                  </div>
                  <div
                    v-if="item.properties.old"
                    class="mb-1"
                  >
                    <p>Old data<br>
                      <strong><pre id="json">{{ item.properties.old }}</pre></strong></p>
                  </div>
                  <div class="mb-1">
                    <p>New data<br>
                      <strong><pre id="json">{{ item.properties.attributes }}</pre></strong></p>
                  </div>
                </div>
              </template>
              <template #customfilters>
                <div
                  v-if="loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('publication-admin')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter($event, 'researcher')"
                  />
                  <hr>
                </div>
                <div
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Type of content</label>
                  <v-select
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter($event, 'researcher')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Type of action</label>
                  <select
                    id=""
                    name=""
                    class="form-select"
                    @input="saveFilter($event.target.value, 'action')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="created">
                      Created
                    </option>
                    <option value="updated">
                      Updated
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >From</label>
                  <date-picker
                    v-model="filters['created_at_from']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'created_at_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >To</label>
                  <date-picker
                    v-model="filters['created_at_to']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'created_at_to')"
                  />
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    CompleteTable,
    DatePicker,
  },
  data() {
    return {
      sortedField: 'Order',
      defaultFields: [
        {
          name: 'User',
          checked: true,
          order: 1,
        },
        {
          name: 'Type of content',
          checked: true,
          order: 2,
        },
        {
          name: 'Type of action',
          checked: true,
          order: 3,
        },
        {
          name: 'Date',
          checked: true,
          order: 3,
        },
      ],
      sortMapping: {
        User: 'causer.name',
        'Type of content': 'class_name',
        'Type of action': 'action_type',
        Date: 'date',
      },
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      data: 'intranetLogs/items',
      dataTotal: 'intranetLogs/itemsTotal',
      loggedUser: 'auth/admin',
      users: 'users/fakeUsers',
      filters: 'filters/filters',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'intranet_logs')
    await this.$store.dispatch('intranetLogs/filter', this.$store.getters['filters/filters'])
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'intranet_logs',
      })
    }

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'cardTypes', url: `${Vue.prototype.$groupUrl}/card-types/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
